import locations from './locations.json' with { type: 'json' };
import { AETERNUM_MAP, findMapDetails, mapIsAeternumMap } from './maps.js';
import regions from './regions.json' with { type: 'json' };
export { regions, locations };
export const checkPointInsidePolygon = (point, polygon) => {
    const x = point[0];
    const y = point[1];
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i][0], yi = polygon[i][1];
        const xj = polygon[j][0], yj = polygon[j][1];
        const intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
        if (intersect)
            inside = !inside;
    }
    return inside;
};
export const findLocation = (position) => {
    return (locations.find((location) => checkPointInsidePolygon(position, location.coordinates))?.name || null);
};
export const findRegion = (position) => {
    return (regions.find((region) => checkPointInsidePolygon(position, region.coordinates))?.name || null);
};
export const findRegions = (positions, map) => {
    if (map && !mapIsAeternumMap(map)) {
        const mapDetail = findMapDetails(map);
        return [mapDetail?.title || 'Unknown'];
    }
    const regions = positions.map((position) => findRegion(position) || AETERNUM_MAP.title);
    const uniqueRegions = regions.filter((region, index) => regions.indexOf(region) === index);
    return uniqueRegions;
};
export const regionNames = regions.map((region) => region.name);
