import maps from './maps.json' with { type: 'json' };
export const mapDetails = maps;
export const AETERNUM_MAP = mapDetails[0];
export const findMapDetails = (map) => {
    const lowerCasedMap = map.toLowerCase();
    return mapDetails.find((mapDetail) => mapDetail.name.toLowerCase() === lowerCasedMap ||
        mapDetail.title.toLowerCase() === lowerCasedMap);
};
export const mapIsAeternumMap = (map) => {
    const mapDetails = findMapDetails(map);
    return mapDetails ? mapDetails.name === AETERNUM_MAP.name : false;
};
